import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import { useStore } from "../../app/stores/store";
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Spareribs Rezepte - Tipps, Tricks und mehr</title>      
                <meta name="description" content="Artikel und Rezepte rund um die Themen Spareribs und Barbecue" />
                <meta name="keywords" content="spareribs, rippchen, barbecue, grillen, rezepte, spareribs im backofen" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Spareribs Rezepte - Tipps, Tricks und mehr</h1>
            <AdvertOwnBlock
                title='Leckere Spareribs zubereiten'
                content='<p>Spareribs sind eine Delikatesse beim amerikanischen <b>Barbecue</b>. Barbecue bezeichnet dabei die Zubereitungsart, die sich deutlich gegenüber dem bei uns beliebten "Grillen" unterscheidet. Während beim Grillen das Fleisch über der offenen Flamme schnell zubereitet wird, dauert die Zubereitungszeit beim Barbecue in der Regel mehrere Stunden, denen das Fleisch bei niedriger Temperatur gegart wird.</p><p>Da nicht jeder einen <b>Barbecue-Smoker</b> oder einen geschlossenen Grill zur Verfügung hat (oder auch die Jahreszeit vielleicht nicht mitspielt), stellen wir auch alternative Zubereitungsmethoden für <b>Spareribs</b> vor, die jeder zu Hause nachmachen kann.</p>'
                image='/banners/spareribs-rezept-banner.png'
                imageTarget=''
                targets={[
                    { linkText: 'Was ist Barbecue?', linkTarget: '/was-ist-barbecue' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />

                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/61KiuiPBDpL._AC_SL1200_.jpg'
                    text='Vielseitig einsetzbarer Grillwagen, Holzkohle Grill Grillkamin Standgrill Räucherofen'
                    link='https://www.amazon.de/dp/B07FM23VPK/ref=as_li_ss_tl?dchild=1&keywords=barbecue&qid=1624270569&sr=8-22&linkCode=ll1&tag=kayomo-21&linkId=f60a579d634d7b2e36ad65eadd1e6263&language=de_DE'
                    title='Barbecue Smoker'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}