import { Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import agent from '../../app/api/agent';
import { IContactInput } from '../../app/models/forms/IContactInput';
import * as Yup from 'yup';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ReCAPTCHA from "react-google-recaptcha";


export default function ContactForm() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();
    
    const [formHidden, setFormHidden] = useState(false);

    const recaptchaKey = process.env.REACT_APP_GOOGLE_CAPTCHA_V3!!;

    useEffect(() => {        
        trackPageView({
            documentTitle: 'Kontaktformular'
        });
    }, [trackPageView])

    var formInitial = {
        name: '',
        email: '',
        message: ''
    } as IContactInput;

    const validationSchema = Yup.object({
        name: Yup.string()        
            .required('Bitte geben Sie Ihren Namen ein')
            .min(5, 'Der Name muss mindestens 5 Zeichen lang sein')
            .max(100),
        email: Yup.string()
            .email('Bitte geben Sie eine gültige Email-Adresse ein')
            .required('Bitte geben Sie eine Email-Adresse ein')
            .max(100),
        message: Yup.string()
            .required('Bitte geben Sie Ihre Nachricht ein, mindestens 20 Zeichen')
            .min(20, 'Die Nachricht muss mindestens 20 Zeichen lang sein')
            .max(3000, 'Die Nachricht kann nicht länger als 3.000 Zeichen sein'),       
        recaptcha: Yup.string()
            .required()
    })

    function handleFormSubmit(input: IContactInput) {
        console.log('Form abgesendet', input);
        try {
            agent.Forms.sendContactForm(input).then(response => {
                setFormHidden(true);
                console.log(response);
            })
        } catch (error) {
            throw error;
        }
    }

    // function recaptchaOnChange(value: any) {
    //     console.log('RECAPTCHA', value);
    // }

    function recaptchaOnError() {
        // console.log('RECAPTCHA-ERROR');
    }

    function recaptchaOnExpired() {
        // console.log('RECAPTCHA-EXPIRED');
    }


    return (
        <Fragment>
            <Helmet>
                <title>Kontakt | {process.env.REACT_APP_PORTAL_NAME}</title> 
                <meta name="description" content="Kontaktformular" />
                <meta name="keywords" content="spareribs, rippchen, barbecue, grillen, rezepte, spareribs im backofen" />         
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="noindex" />
        </Helmet>

        <h1>Kontakt</h1>
        <p className="lead">Bitte verwenden Sie das Formular, um mit uns in Kontakt zu treten. Wir werden Ihre Anfrage schnellstmöglich bearbeiten.</p>
        <hr />

        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={formInitial}
            onSubmit={values => handleFormSubmit(values)}>
            {({handleSubmit, isValid, isSubmitting, dirty, handleChange, setFieldValue, errors}) => (
            <Form noValidate onSubmit={handleSubmit} hidden={formHidden}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder="Ihr Name" onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Ihre Email" onChange={handleChange} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="message">
                    <Form.Label>Ihre Nachricht</Form.Label>
                    <Form.Control as="textarea" name="message" rows={10} onChange={handleChange} isInvalid={!!errors.message}/>
                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                </Form.Group>
                <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={(response) => { setFieldValue("recaptcha", response); }}
                    onErrored={recaptchaOnError}
                    onExpired={recaptchaOnExpired}
                />
                <Button style={{marginTop: '1em'}}
                disabled={isSubmitting || !dirty || !isValid}
                variant="success"
                type="submit">
                    Absenden
                </Button>
            </Form>
            )}
        </Formik>

        <Alert hidden={!formHidden} variant="success">
            Ihe Anfrage wurde übermittelt, vielen Dank!
        </Alert>

        </Fragment>
    )
}